<template>
    <div class="bpm-management">
        <base-header type="secondary" class="pb-6 pb-8 pt-2 pt-md-3">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row pb-3">
                <div class="col-12">
                    <card header-classes="bg_transparent" body-classes="d-flex">
                        <div class="container">
                            <b-tabs v-model="tabIndex">
                                <b-tab :title="$t('BPMManagement.pendingUsers')" active>
                                    <div class="container">
                                        <div class="row pt-2">
                                            <div class="col-3">
                                                <base-button class="text-right"
                                                    type="default-button"
                                                    size="md"
                                                    @click="paySelected">
                                                    {{ $t('liquidityManagement.pool.paySelected') }}
                                                </base-button>
                                            </div>
                                            <div class="offset-5 col-4">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <base-input v-model="filterUsers"></base-input>
                                                    </div>
                                                    <div class="col-4">
                                                        <base-button
                                                        type="default-button"
                                                        @click="search">
                                                        {{ $t('BPMManagement.search') }}
                                                        </base-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table 
                                            :fields="pendingUsersFields"
                                            :items="pendingUsers"
                                            class="bpm-users-table">
                                            <template #head(userAddress)="data">
                                                <div class="row form-inline">
                                                    <div class="col-1 p-0">
                                                        <base-checkbox v-model="selectAll">
                                                        </base-checkbox>
                                                    </div>
                                                    <div class="col-11 p-0 text-center">
                                                        {{ data.label}}
                                                    </div>
                                                </div>
                                            </template>
                                            <template #cell(userAddress)="data">
                                                <div class="row form-inline">
                                                <div class="col-1 p-0">
                                                    <base-checkbox v-model="data.item.selected">
                                                    </base-checkbox>
                                                </div>
                                                <div class="col-11 p-0 text-center">
                                                    {{ data.value}}
                                                </div>
                                                </div>
                                            </template>
                                            <template #cell(action)="data">
                                                <i @click="showVideo(data.item)" class="fas fa-solid fa-play" style="cursor: pointer; padding-left: 15% !important;"></i>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-tab>
                                <b-tab :title="$t('BPMManagement.paidUsers')">
                                    <div class="container">
                                        <div class="row pt-2">
                                            <div class="offset-8 col-4">
                                                <div class="row">
                                                <div class="col-8">
                                                    <base-input v-model="filterUsers"></base-input>
                                                </div>
                                                <div class="col-4">
                                                    <base-button
                                                    type="default-button"
                                                    @click="search">
                                                    {{ $t('BPMManagement.search') }}
                                                    </base-button>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <b-table
                                            :fields="paidUsersFields"
                                            :items="paidUsers"
                                            class="bpm-users-table">
                                            <template #cell(action)="data">
                                                <i @click="showVideo(data.item)" class="fas fa-solid fa-play" style="cursor: pointer; padding-left: 15% !important;"></i>
                                            </template>
                                        </b-table>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                        <div slot="footer" class="align-items-right" style="padding: 0">
                            <b-pagination class="no-border"
                                v-model="currentPage"
                                :total-rows="totalUsersFiltered"
                                :per-page="perPage"
                                align="right"
                                style="padding: 0"
                                @input="updatePage()"
                                hide-goto-end-buttons>
                            </b-pagination>
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <b-modal size="xl" ref="video-modal" v-bind:title="video.videoName" :hide-footer=true>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <video width="360" height="280" crossorigin="anonymous" controls>
                                        <source v-bind:src="video.videoUrl" type='video/webm'>
                                        <source v-bind:src="video.videoUrl" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-4" v-if="video.mesh !== ''">
                            <div class="col-12">
                                <div class="embed-responsive embed-responsive-16by9">
                                    <video width="360" height="280" crossorigin="anonymous" controls>
                                        <source v-bind:src="video.mesh" type='video/webm'>
                                        <source v-bind:src="video.mesh" type="video/mp4">
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 v-text="video.videoName"></h4>
                            </div>
                        </div>
                        <div class="row upload-date">
                            <div class="col-md-12">
                                <span v-text="$t('videoList.videoModal.uploaded') + video.createdAt"></span>
                            </div>
                        </div>
                        <div class="row reward-aht">
                            <div class="col-md-3">
                                <input v-model="video.rewardAmount" class="form-control form-control-sm" :disabled="(video.rewardTx !== '')"/>
                            </div>
                            <div class="col-md-9">
                                <span>{{ $t('videoList.videoModal.reward') }}</span>
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-md-12 approve">
                                <base-button v-show="(video.rewardTx === '')" type="default-button" class="modal-button" @click="approve(video)" :disabled="(video.status === 1)">
                                    {{ $t('videoList.videoModal.approve') }}   
                                </base-button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{ $t('videoList.videoModal.rejectionNote') }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" rows="4" v-model="video.rejectionNote"></textarea>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-12 reject">
                                <base-button v-show="(video.rewardTx === '')" size="md" type="default" class="modal-button" @click="reject(video)" :disabled="(video.status === 2)">
                                    {{ $t('videoList.videoModal.reject') }}
                                </base-button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </b-modal>

        <b-modal size="md" ref="multipay-modal" :hide-footer=true>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="row reward-aht">
                            <div class="col-md-3">
                                <input v-model="multipay.rewardAmount" class="form-control form-control-sm"/>
                            </div>
                            <div class="col-md-9">
                                <span>{{ $t('videoList.videoModal.reward') }}</span>
                            </div>
                        </div>
                        <div class="row pt-4">
                            <div class="col-md-12 approve">
                                <base-button type="default-button" class="modal-button" @click="approveAll()">
                                    {{ $t('videoList.videoModal.approve') }}   
                                </base-button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{ $t('videoList.videoModal.rejectionNote') }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" rows="4" v-model="multipay.rejectionNote"></textarea>
                            </div>
                        </div>
                        <div class="row pt-3">
                            <div class="col-md-12 reject">
                                <base-button size="md" type="default" class="modal-button" @click="rejectAll()">
                                    {{ $t('videoList.videoModal.reject') }}
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BTabs, BTab, BTable } from 'bootstrap-vue';

export default {
    data () {
        return {
            filterUsers: '',
            tabIndex: 0,
            pendingUsersFields: [
                {
                    key: 'paymentAddress',
                    sortable: true,
                    label: this.$t('general.address')
                },
                {
                    key: 'rewardAmount',
                    sortable: true,
                    label: this.$t('BPMManagement.reward')
                },
                {
                    key: 'country',
                    sortable: true,
                    label: this.$t('BPMManagement.country') 
                },
                {
                    key: 'uploaded',
                    sortable: true,
                    label: this.$t('BPMManagement.date')   
                },
                {
                    key: 'action',
                    sortable: true,
                    label: this.$t('BPMManagement.action')
                }
            ],
            paidUsersFields: [
                {
                    key: 'paymentAddress',
                    sortable: true,
                    label: this.$t('general.address')
                },
                {
                    key: 'rewardAmount',
                    sortable: true,
                    label: this.$t('BPMManagement.reward')
                },
                {
                    key: 'country',
                    sortable: true,
                    label: this.$t('BPMManagement.country') 
                },
                {
                    key: 'uploaded',
                    sortable: true,
                    label: this.$t('BPMManagement.date')   
                },
                {
                    key: 'rewardTx',
                    sortable: true,
                    label: this.$t('BPMManagement.transaction')
                },
                {
                    key: 'action',
                    sortable: true,
                    label: this.$t('BPMManagement.action')
                }
            ],
            video: {},
            currentPage: 1,
            perPage: 20,
            selectAll: false,
            multipay: {
                rewardAmount: 0,
                rejectionNote: ''
            }
        }
    },
    components: {
        BTabs,
        BTab,
        BTable
    },
    computed: {
        currentAccount () {
            return this.$store.getters.getAddressMetamask
        },
        paidUsers () {
            return this.$store.getters.getBPMPaidUsers
        },
        pendingUsers () {
            return this.$store.getters.getBPMPendingUsers
        },
        totalUsersFiltered () {
            return this.tabIndex === 0 ? this.$store.getters.getTotalPendingUsers : this.$store.getters.getTotalPaidUsers
        },
        isManager () {
            return this.$store.getters.getIsManager
        }
    },
    methods: {
        showVideo (video) {
            this.$refs['video-modal'].show()

            this.video = video;
        },
        async approve (video) {
            if (this.currentAccount) {
                    video.address = this.currentAccount

                await this.$store.dispatch('approveBPMVideo', video)
                    .then(() => {
                        this.$refs['video-modal'].hide()
                        this.search()
                        this.$alert('Video approved');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
                } else {
                    this.$alert(
                        'Please, connect with Metamask',
                        'Warning',
                        'warning')
                }
        },
        async reject (video) {
            await this.$store.dispatch('rejectBPMVideo', video)
                    .then(() => {
                        this.$refs['video-modal'].hide()
                        this.search()
                        this.$alert('Video rejected');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
        },
        async search() {
            this.selectAll = false;
            let filters= [];

            if(this.filterUsers !== '') {
                filters.push('address=' + this.filterUsers);
            }

            filters.push('page=' + this.currentPage);

            await this.$store.dispatch('getBPMList', filters);
            await this.$store.dispatch('getBPMCountUser', filters);
        },
        updatePage () {
            this.search();
        },
        paySelected () {
            const selectedUsers = this.pendingUsers.filter(user => user.selected);

            if(selectedUsers.length > 0) {
                this.$refs['multipay-modal'].show()
            }
        },
        changeSelected (status) {
            this.pendingUsers.forEach(user => {
                user.selected = status
            });
        },
        async approveAll() {
            if (this.currentAccount) {
                await this.$store.dispatch('isBPMManager', this.currentAccount)

                if (this.isManager) {
                    const data = {
                        address: this.currentAccount,
                        rewardAmount: this.multipay.rewardAmount,
                        users: this.pendingUsers.filter(user => user.selected)
                    }

                    await this.$store.dispatch('approveMultiBPMVideo', data)
                        .then(() => {
                            this.$refs['multipay-modal'].hide()
                            this.search()
                            this.$alert('Video approved');
                        }).catch ((error) => {
                            this.$alert(
                                    'Something went wrong, Please try again',
                                    'Warning',
                                    'warning')
                            return Promise.reject(error.response)
                        })
                } else {
                    this.$alert(
                        this.$t('liquidityManagement.notManager'),
                        'Error',
                        'error')
                }
            } else {
                this.$alert(
                    'Please, connect with Metamask',
                    'Warning',
                    'warning')
            }
        },
        async rejectAll() {
            const users = this.pendingUsers.filter(user => user.selected)
            const videosId = users.map(user => user.videoId)
            const data = {
                rejectionNote: this.multipay.rejectionNote,
                videosId: videosId
            }

            await this.$store.dispatch('rejectMultiBPMVideo', data)
                    .then(() => {
                        this.$refs['multipay-modal'].hide()
                        this.search()
                        this.$alert('Video rejected');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
        }
    },
    async mounted () {
        this.search();
    },
    watch: {
        selectAll (value) {
            this.changeSelected(value);
        }
    }
}
</script>

<style>
  .bpm-management .nav-item {
    padding-left: 1rem;
    border-bottom: 1px solid #dee2e6;
  }

  .bpm-management .nav-link {
    color: #b2b3b7;
    background-color: white;
    font-weight: 400;
    font-size: 14px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: .5rem;
    border-radius: 15px 15px 0 0;
    border: 1px solid #b2b3b7;
    border-bottom: 1px solid white;
  }

  .bpm-management .nav-link:hover {
    border: 1px solid #b2b3b7;
    border-bottom: 1px solid white;
  }

  .bpm-management .nav-link.active {
    background-color: #9fdbe7;
    border-bottom: 1px solid #9fdbe7;
    color: white;
    font-weight: 600;
  }

  .bpm-management .tab-content {
    background-color: white;
    border-radius: 5px;
  }

  .bpm-management thead {
    background-color: #7a92aa;
    color: white;
  }

  .bpm-management .pointer {
    cursor: pointer;
  }

  .bpm-management .bpm-users-table tbody tr:hover {
    background: rgba(122, 146, 170, .40)
  }
</style>
