<template>
    <div>
        <base-header
        type="secondary"
        class="pb-6 pb-8 pt-2 pt-md-7">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <card header-classes="bg_transparent" body-classes="d-flex">
                        <div slot="header" class="row align-items-center">
                            <div class="col-md-6">
                                <h5 class="h3 mb-0">{{ $t('surveyManagement.title') }}</h5>
                            </div>
                            <div class="offset-md-2 col-md-4 text-right">
                                <base-button v-on:click="showSurveys" :type="surveysClass" size="sm">{{ $t('surveyManagement.surveys') }}</base-button>
                                <base-button v-on:click="showCreateSurvey" :type="newSurveyClass" size="sm">{{ $t('surveyManagement.createNewSurvey') }}</base-button>
                            </div>
                        </div>
                        <div class="container" v-if="surveysTab">
                            <div class="row">
                                <div class="col-12">
                                    <table class="survey-table table-bordered">
                                        <thead>
                                            <th>{{ $t('surveyManagement.surveysTable.title') }}</th>
                                            <th>{{ $t('surveyManagement.surveysTable.rewardPool') }}</th>
                                            <th>{{ $t('surveyManagement.surveysTable.creationDate') }}</th>
                                            <th>{{ $t('surveyManagement.surveysTable.endDate') }}</th>
                                            <th>{{ $t('surveyManagement.surveysTable.options') }}</th>
                                            <th>{{ $t('surveyManagement.surveysTable.actions') }}</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(survey, index) in surveysFiltered" :key="index">
                                                <td>{{ survey.title}}</td>
                                                <td>{{ survey.rewardPool}}</td>
                                                <td>{{ formatDate(survey.creationDate) }}</td>
                                                <td>{{ formatDate(survey.endDate) }}</td>
                                                <td class="underline"> <a @click="showOptions(survey.options)">View</a></td>
                                                <td v-if="surveyName !== survey.surveyName" class="underline" @click="setActive(survey.surveyName)">
                                                    Publish
                                                </td>
                                                <td v-if="surveyName === survey.surveyName">Active</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div slot="footer" class="align-items-right pt-2">
                                        <b-pagination class="no-border"
                                            v-model="currentPage"
                                            :total-rows="surveys.length"
                                            :per-page="perPage"
                                            align="right"
                                            style="padding: 0"
                                            hide-goto-end-buttons>
                                        </b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="container" v-if="newSurveyTab">
                            <div class="row">
                                <div class="col-12">
                                    <h2>New Live Survey</h2>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <form>
                                        <div class="row">
                                            <div class="col-6">
                                                <label for="survey-name" class="col-sm-12">
                                                    {{ $t('surveyManagement.newSurvey.name') }}
                                                </label>
                                                <div class="col-sm-12">
                                                    <input v-model="newSurveyName" type="text" class="form-control" id="survey-name">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label for="end-date" class="col-sm-12">
                                                    {{ $t('surveyManagement.newSurvey.endDate') }}
                                                </label>
                                                <div class="col-sm-12">
                                                    <input v-model="endDate" type="date" class="form-control" id="end-date">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row pt-2">
                                            <div class="col-6">
                                                <label class="col-sm-12">
                                                    {{ $t('surveyManagement.newSurvey.options') }}
                                                </label>
                                                <div class="col-sm-12">
                                                    <div class="row" v-for="(item, index) in options" :key="index">
                                                        <div class="col-sm-12 pt-1">
                                                            <input v-model="options[index]" type="text" class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="offset-sm-11 col-sm-1 text-right pt-1">
                                                        <a class="add-option" @click="addOption">
                                                            <b-icon icon="plus-circle-fill" 
                                                                class="rounded-circle"
                                                                font-scale="1.5">
                                                            </b-icon>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <label for="reward-pool" class="col-sm-12">
                                                    {{ $t('surveyManagement.newSurvey.rewardPool') }}
                                                </label>
                                                <div class="col-sm-12">
                                                    <input v-model="rewardPool" type="number" class="form-control" id="reward-pool">
                                                </div>
                                            </div>
                                        </div>
                                        <button type="button" class="btn btn-primary" @click="saveSurvey">Save</button>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </card>
                </div>
            </div>
        </div>

        <b-modal id="options-modal" title="Options" ok-only>
            <template>
                <table class="options-table table-bordered table-hover">
                    <tbody>
                        <tr v-for="(option, index) in surveyOptions" :key="index">
                            <td>{{ option }}</td>
                        </tr>
                    </tbody>
                </table>
            </template>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
import detectEthereumProvider from '@metamask/detect-provider'
import { required, minLength, minValue } from 'vuelidate/lib/validators'

export default {
    computed: {
        surveys () {
            return this.$store.getters.getSurveys
        },
        surveysFiltered () {
            return this.$store.getters.getSurveys.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
        },
        surveyName () {
            return this.$store.getters.getSurveyName
        }
    },
    data () {
        return {
            surveysClass: 'default-button',
            newSurveyClass: 'secondary',
            surveysTab: true,
            newSurveyTab: false, 
            newSurveyName: '',
            options: [''],
            endDate: null,
            rewardPool: 0,
            surveyOptions: [],
            isConnected: false,
            metamaskAccount: '',
            currentPage: 1,
            perPage: 20
        }
    },
    methods: {
        addOption() {
            this.options.push('')
        },
        async saveSurvey() {
            this.$v.$touch()

            if (this.$v.$invalid) {
                this.$alert(
                    'All the fields are mandatory',
                    'Warning',
                    'warning')
            } else {
                const ahtTransactions = require('../lib/ahtTransactions.js')
                let parts = this.endDate.split('-')

                let data = {
                    surveyName: this.newSurveyName,
                    surveyOptions: this.options,
                    endDate: new Date(parts[0], parts[1] - 1, parts[2]),
                    rewardPool: this.rewardPool,
                    address: this.metamaskAccount
                }

                await ahtTransactions.saveSurvey(data)

                this.$alert(
                    'Survey created successfully',
                    'Success',
                    'success')

                await this.$store.dispatch('getSurveyList')

                this.showSurveys()
            }
        },
        formatDate(date) {
            let d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
            
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        },
        showOptions(options) {
            this.surveyOptions = options
            this.$bvModal.show('options-modal')
        },
        async setActive(surveyName) {
            await this.$store.dispatch('setSurveyActive', {
                surveyName: surveyName,
                address: this.metamaskAccount
                })
            
            await this.$store.dispatch('getSurveyName')

            this.$alert(
                    'Survey published successfully',
                    'Success',
                    'success')
        },
        async connect() {
            const provider = await detectEthereumProvider()

            if (provider) {
                ethereum
                    .request({ method: 'eth_requestAccounts' })
                    .then(this.handleAccountsChanged)
                    .catch((err) => {
                        if (err.code === 4001) {
                            console.log('Please connect to MetaMask.')
                        } else {
                            console.error(err)
                        }
                    })
            } else {
                this.$alert(
                    'Metamask is not installed or not running',
                    'Error',
                    'error')
                this.isConnected = false
            }
        },
        handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                this.isConnected = false
                console.log('Please connect to MetaMask.')
            } else if (accounts[0] !== this.metamaskAccount) {
                this.metamaskAccount = accounts[0]
                this.isConnected = true
            } else if (accounts[0] === this.metamaskAccount && !this.isConnected) {
                this.isConnected = true
            }
        },
        startApp(provider) {
            if (provider !== window.ethereum) {
                console.error('Do you have multiple wallets installed?')
                return
            }

            this.connect()
        },
        showCreateSurvey () {
            this.newSurveyClass = 'default-button'
            this.surveysClass = 'secondary'
            this.surveysTab = false
            this.newSurveyTab = true
        },
        showSurveys () {
            this.newSurveyClass = 'secondary'
            this.surveysClass = 'default-button'
            this.surveysTab = true
            this.newSurveyTab = false
        }
    },
    async mounted () {
        await this.$store.dispatch('getSurveyName')
        await this.$store.dispatch('getSurveyList')

        const provider = await detectEthereumProvider()

        if (provider) {
            ethereum.on('accountsChanged', this.handleAccountsChanged)
            await this.startApp(provider)
        } else {
            this.$alert(
                'Metamask is not installed or not running',
                'Error',
                'error')
            this.isConnected = false
            console.log('Metamask is not installed or not running')
        }
    },
    validations: {
        surveyName: {
            required,
            minLength: minLength(1)
        },
        options: {
            required,
            minLength: minLength(1),
            $each: {
                required,
                minLength: minLength(1)
            }
        },
        endDate: {
            required
        },
        rewardPool: {
            required,
            minValue: minValue(0)
        }
    }
}
</script>

<style scoped>
.survey-table, .options-table {
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    float: none;
}

tbody tr:hover {
    background: rgba(122, 146, 170, .40)
}

thead th {
    background-color: #7a92aa;
}

td {
    text-align: left;
    padding-left: 2%;
    padding-top: 3px;
    padding-bottom: 3px;
}

th {
    color: white;
    text-align: left;
    padding-left: 2%;
    padding-top: 4px;
    padding-bottom: 4px;
}

.underline {
    text-decoration: underline;
    color: rgb(18, 75, 129);
    cursor: pointer;
}

.add-option {
    cursor: pointer;
}
</style>