<template>
    <div>
        <base-header
        type="secondary"
        class="pb-6 pb-8 pt-2 pt-md-7">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <card header-classes="bg_transparent" body-classes="d-flex">
                        <div slot="header" class="row align-items-center">
                            <div class="col-md-6">
                                <h5 class="h3 mb-0">{{ $t('userRewards.title') }}</h5>
                            </div>
                            <div class="offset-md-2 col-md-4 text-right">
                                <base-button v-on:click="showPendingList" :type="pedingRewardListClass" size="sm">{{ $t('userRewards.userPendingReward') }}</base-button>
                                <base-button v-on:click="showRewardedList" :type="rewardedListClass" size="sm">{{ $t('userRewards.userRewarded') }}</base-button>
                                <span style="cursor:pointer" v-on:click="search()"><i class="fas fa-sync-alt"></i></span>
                            </div>
                            <div class="offset-6 col-6 text-right pt-1">
                                {{ $t('userRewards.contractBalance', { amount: contractBalance }) }}
                            </div>
                            <div class="offset-6 col-6 text-right pt-1">
                                {{ $t('userRewards.amountReward', { amount: contractBalance - amountStacked }) }}
                            </div>
                            <div class="col-12 pt-2" v-if="pending">
                                <div class="row">
                                    <div class="col-md-5 offset-md-5 text-right">
                                        {{ $t('userRewards.totalPendingReward', { amount: totalPendingReward }) }}
                                    </div>
                                    <div class="col-md-2 text-center">
                                        <base-button v-on:click="payReward" type="default-button" size="sm">{{ $t('userRewards.payReward') }}</base-button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 pt-2" v-if="rewarded">
                                <div class="row">
                                    <div class="col-md-4 offset-md-8 text-right">
                                        {{ $t('userRewards.amountAwarded', { amount: amountAwarded }) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="user-reward-table table-bordered">
                            <thead>
                                <th class="p-1" v-if="pending">{{ $t('userRewards.table.pay') }}</th>
                                <th>{{ $t('userRewards.table.address') }}</th>
                                <th>{{ $t('userRewards.table.reward') }}</th>
                                <th>{{ $t('userRewards.table.rewarded') }}</th>
                                <th>{{ $t('userRewards.table.stakingStatus') }}</th>
                            </thead>
                            <tbody>
                                <tr v-for="(user, index) in rewardInfoFiltered" :key="index">
                                    <td class="pay" v-if="pending">
                                        <base-checkbox v-if="pending && user.stakingTime >= 72"
                                            v-model="user.pay" >
                                        </base-checkbox>
                                    </td>
                                    <td class="address">{{ user.address }}</td>
                                    <td class="reward">{{ user.reward }}</td>
                                    <td class="rewarded">{{ getRewardedStatus(user.rewarded) }}</td>
                                    <td class="stake-status">{{ getStakingStatus(user.stakeStatus) }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div slot="footer" class="align-items-right pt-2">
                            <b-pagination class="no-border"
                                v-model="currentPage"
                                :total-rows="rewardInfo.length"
                                :per-page="perPage"
                                align="right"
                                style="padding: 0"
                                hide-goto-end-buttons>
                            </b-pagination>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import detectEthereumProvider from '@metamask/detect-provider'

export default {
    data () {
        return {
            pedingRewardListClass: 'default-button',
            rewardedListClass: 'secondary',
            pending: true,
            rewarded: false,
            totalPendingReward: 0,
            amountAwarded: 0,
            metamaskAccount: '',
            isConnected: false,
            currentPage: 1,
            perPage: 20
        }
    },
    computed: {
        rewardInfo () {
            let rewardInfo = []

            if (this.pending)
                rewardInfo = this.filterUsersPending(this.$store.getters.getRewardInfo)
            else
                rewardInfo = this.filteRewardedUsers(this.$store.getters.getRewardInfo)

            return rewardInfo
        },
        rewardInfoFiltered () {
            return this.rewardInfo.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
        },
        surveyName () {
            return this.$store.getters.getSurveyName
        },
        contractBalance () {
            return this.$store.getters.getContractBalance
        },
        amountStacked () {
            return this.$store.getters.getAmountStacked
        }
    },
    methods: {
        async connect() {
            const provider = await detectEthereumProvider()

            if (provider) {
                ethereum
                    .request({ method: 'eth_requestAccounts' })
                    .then(this.handleAccountsChanged)
                    .catch((err) => {
                        if (err.code === 4001) {
                            console.log('Please connect to MetaMask.')
                        } else {
                            console.error(err)
                        }
                    })
            } else {
                this.$alert(
                    'Metamask is not installed or not running',
                    'Error',
                    'error')
                this.isConnected = false
            }
        },
        handleAccountsChanged(accounts) {
            if (accounts.length === 0) {
                this.isConnected = false
                console.log('Please connect to MetaMask.')
            } else if (accounts[0] !== this.metamaskAccount) {
                this.metamaskAccount = accounts[0]
                this.isConnected = true
            } else if (accounts[0] === this.metamaskAccount && !this.isConnected) {
                this.isConnected = true
            }
        },
        getStakingStatus(status) {
            let stakingStatus = ''

            stakingStatus = status? this.$t('userRewards.table.active') : this.$t('userRewards.table.inactive')

            return stakingStatus
        },
        getRewardedStatus(status) {
            let rewardedStatus = ''

            rewardedStatus = status? this.$t('userRewards.table.paid') : this.$t('userRewards.table.pending')

            return rewardedStatus
        },
        filterUsersPending(data) {
            let usersPending = []
            let self = this
            this.totalPendingReward = 0

            data.forEach(user => {
                if (!user.rewarded) {
                    usersPending.push(user)
                    self.totalPendingReward += Number(user.reward)
                }
            });

            return usersPending
        },
        filteRewardedUsers(data) {
            let rewardedUsers = []
            let self = this
            this.amountAwarded = 0

            data.forEach(user => {
                if (user.rewarded) {
                    rewardedUsers.push(user)
                    self.amountAwarded += Number(user.reward)
                }
            });

            return rewardedUsers
        },
        async payReward() {
            let usersToPay = []
            let amountToPay = 0

            await this.$store.dispatch('getSurveyContractBalance');
            await this.$store.dispatch('getAmountStacked')

            this.rewardInfo.forEach(user => {
                if(user.pay) {
                    usersToPay.push(user)
                    amountToPay += Number(user.reward)
                }
            })

            if (amountToPay <= (this.contractBalance - this.amountStacked)) {
                if(usersToPay.length > 0) {
                    if(usersToPay.length > 10) {
                        this.$alert(
                            'Max users selected should be 10',
                            'Warning',
                            'warning'
                        )
                    } else {
                        let data = {
                            usersToPay: usersToPay,
                            userAddress: this.metamaskAccount
                        }
                        
                        await this.$store.dispatch('sendRewards', data)

                        this.search()
                    }
                } else {
                    this.$alert(
                        'No user selected to pay reward',
                        'Warning',
                        'warning')
                }
            } else {
                this.$alert(
                    'The contract has not enough AHT to pay rewards',
                    'Error',
                    'error')
            }
        },
        async search() {
            await this.$store.dispatch('getRewardInfo', this.metamaskAccount)
            await this.$store.dispatch('getSurveyContractBalance');
            await this.$store.dispatch('getAmountStacked')
        },
        showPendingList() {
            this.pedingRewardListClass = 'default-button'
            this.rewardedListClass = 'secondary'
            this.pending = true
            this.rewarded = false
        },
        showRewardedList() {
            this.pedingRewardListClass = 'secondary'
            this.rewardedListClass = 'default-button'
            this.rewarded = true
            this.pending = false
        },
        startApp(provider) {
            if (provider !== window.ethereum) {
                console.error('Do you have multiple wallets installed?')
                return
            }

            this.connect()
        }
    },
    async mounted () {
        await this.$store.dispatch('getSurveyName')
        const provider = await detectEthereumProvider()

        if (provider) {
            ethereum.on('accountsChanged', this.handleAccountsChanged)
            await this.startApp(provider)
        } else {
            this.$alert(
                'Metamask is not installed or not running',
                'Error',
                'error')
            this.isConnected = false
            console.log('Metamask is not installed or not running')
        }
    },
    watch: {
        metamaskAccount () {
            this.search()
        }
    }
}
</script>

<style scoped>
.user-reward-table {
    width: 100%;
    margin-left: 1px;
    margin-right: 1px;
    float: none;
}

thead th {
    background-color: #7a92aa;
}

tbody tr:hover {
    background: rgba(122, 146, 170, .40)
}

td {
    text-align: left;
    padding-left: 2%;
    padding-top: 3px;
    padding-bottom: 3px;
}

th {
    color: white;
    text-align: left;
    padding-left: 2%;
    padding-top: 4px;
    padding-bottom: 4px;
}

td.pay {
    width: 5%;
}

td.address {
    width: 40%;
}

td.reward {
    width: 25%;
}

td.rewarded {
    width: 15%;
}

td.stake-status {
    width: 15%;
}
</style>
