<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-7">
      <div class="card bg-white shadow border-0">
        <div class="card-body bg-transparent pb-1">
          <div class="text-muted text-center mt-2 mb-3"><h3>{{ $t('login.title') }}</h3></div>
          <div class="text-muted text-center mt-2 mb-3"><p>{{ $t('login.desc') }}</p></div>
        </div>

        <form role="form">
          <div class="row pl-4 pr-4">
            <div class="col-3" v-for="(input, index) in model" :key="index">
              <base-input class="input-group-alternative mb-3"
                :placeholder="index+1"
                v-model.trim="model[index].word"
                :class="{ 'has-danger': $v.word.$error }">
                <!-- @blur="$v.word.touch()"> -->
              </base-input>
            </div>
          </div>
          <div class="text-center">
            <base-button @click.prevent="login" type="primary" class="my-4">{{ $t('login.submit') }}</base-button>

            <p v-if="$v.$anyError" class="text-danger">{{ $t('register.formErrorHandler') }}</p>
            <p v-if="error === 'misspelled'" class="text-danger">{{ $t('register.misspelledError') }}</p>
            <p v-if="error === 'unauthorized'" class="text-danger">{{ $t('register.unauthorizedError') }}</p>
          </div>
        </form>

        </div>
      </div>
    </div>
  </template>
<script>
import { EthHdWallet } from 'eth-hd-wallet'
import { required, minLength } from 'vuelidate/lib/validators'

require('../lib/ethutil')
require('buffer/').Buffer


  export default {
    name: 'login',
    data() {
      return {
        model: [
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' },
          { word: '' }
        ],
        error: null
      }
    },
    validations: {
      word: {
        required,
        minLength: minLength(2)
      }
    },
    methods: {
      async login () {
        const seed = this.model.map(e => e.word).toString().replace(/,/gi, ' ')

        try {
          const wallet = await EthHdWallet.fromMnemonic(seed)
          wallet.generateAddresses(1)

          const account = {
            address: '0x' + wallet._children[0].wallet.getAddress().toString('hex'),
            publicKey: '0x' + wallet._children[0].wallet.getPublicKey().toString('hex'),
            privateKey: wallet._children[0].wallet.getPrivateKey().toString('hex'),
          }

          await this.$store.dispatch('login', account)
            .then(() => {
              this.$router.push({ name: 'Admin Panel' })
            }).catch ((error) => {
              if (error.response.status === 403) {
                this.error = 'unauthorized'
                return;
              }

              return Promise.reject(error.response)
            })
        } catch(e) {
          this.error = 'misspelled'
        }
      }
    }
  }
</script>
<style>
</style>
