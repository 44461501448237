<template>
    <div>
        <base-header 
        type="secondary" 
        class="pb-6 pb-8 pt-2 pt-md-7">
        <h2>{{ $t('videoList.filter') }}</h2>
        
        <div class="row">
            <div class="col-12 col-md-3 pt-2">
                <base-input :placeholder="$t('videoList.filters.text')"
                    v-model="name">
                </base-input>
            </div>
            <div class="col-12 col-md-4 pt-2">
                <multiselect v-model="selectedStatus" label="label" :placeholder="$t('videoList.filters.filterByStatus')"
                    track-by="code" 
                    open-direction="bottom"
                    :options="status" 
                    :multiple="true" 
                    :searchable="false"
                    :clear-on-select="false" 
                    :close-on-select="false" 
                    :hide-selected="false">
                <template slot="tag" slot-scope="{ option, remove }"><span class="custom__tag"><span>{{ option.label }}</span><span class="custom__remove" @click="remove(option)">X</span></span></template>
                </multiselect>
            </div>
            <div class="col-12 col-md-3 pt-2">
                <div class="form-group input-group date-picker">
                    <date-range-picker v-model="dateRange" :options="dateOptions" className="form-control"/>
                    </div>
            </div>
            <div class="col-12 col-md-2 pt-2">
                <base-button type="default-button" @click="search()">{{ $t('videoList.filters.search') }}</base-button>
            </div>
        </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <card header-classes="bg_transparent" body-classes="d-flex">
                        <div slot="header" class="row align-items-center">
                            <div class="col-md-5">
                                <h5 v-if="isVideo" class="h3 mb-0">{{ $t('videoList.title') }}</h5>
                                <h5 v-if="!isVideo" class="h3 mb-0">{{ $t('imageList.title') }}</h5>
                            </div>
                            <div class="col-md-2">
                                <span v-if="isVideo" class="text-muted" v-text="totalVideos +  $t('videoList.totalVideos') "></span>
                                <span v-if="!isVideo" class="text-muted" v-text="totalImages +  $t('imageList.totalImages') "></span>
                            </div> 
                            <div class="col-md-5">
                                <base-button v-on:click="showVideoList()"  size="sm" :type="videoListButtonClass">{{ $t('videoList.communityVideo') }}</base-button>
                                <base-button v-on:click="showDefaultVideoList()" size="sm" :type="defaultVideoListButtonClass">{{ $t('videoList.defaultVideo') }}</base-button>
                                <base-button v-on:click="showImageList()" size="sm" :type="imageListButtonClass">{{ $t('imageList.communityImages') }}</base-button>
                                <span style="cursor:pointer" v-on:click="updatePage()"><i class="fas fa-sync-alt"></i></span>
                            </div>
                        </div>
                        <div slot="header" class="row alig-item-right" >
                            <div class="upload-default-video" 
                                v-show="defaultVideoListButtonClass !== 'secondary'">
                                <base-button v-on:click="addDefaultVideo()" size="sm" type="default-button">
                                    <i class="fa fa-upload"></i> {{ $t('videoList.uploadDefaultVideo') }}
                                </base-button>
                            </div>
                        </div>
                        <table v-if="isVideo" class="video-table table-bordered">
                            <thead>
                                <tr>
                                    <th>{{ $t('videoList.table.videoName') }}</th>
                                    <th v-show="videoListButtonClass !== 'secondary'">{{ $t('videoList.table.userName') }}</th>
                                    <th>{{ $t('videoList.table.uploadedDate') }}</th>
                                    <th v-show="videoListButtonClass !== 'secondary'">{{ $t('videoList.table.lang') }}</th>
                                    <th>{{ $t('videoList.table.status') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(video, index) in videos" :key="index" @click="showVideo(video)" >
                                    <td class="name-video-default text-muted" >{{ video.videoName }}</td>
                                    <td class="user-name" v-show="videoListButtonClass !== 'secondary'">{{ video.userNickname }}</td>
                                    <td class="created-date-default text-muted">{{ video.createdAt }}</td>
                                    <td class="language-default text-muted">{{ video.language }}</td>
                                    <td class="status text-muted" v-show="videoListButtonClass !== 'secondary'">{{ video.statusDescription }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table v-if="!isVideo" class="video-table image-table table-bordered">
                            <thead>
                                <tr>
                                    <th>{{ $t('imageList.table.imageName') }}</th>
                                    <th>{{ $t('imageList.table.userName') }}</th>
                                    <th>{{ $t('imageList.table.uploadedDate') }}</th>
                                    <th>{{ $t('imageList.table.country') }}</th>
                                    <th>{{ $t('imageList.table.status') }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(image, index) in images" :key="index" @click="showImage(image)" >
                                    <td class="name-video-default text-muted" >{{ image.imageName }}</td>
                                    <td class="user-name">{{ image.userNickname }}</td>
                                    <td class="created-date-default text-muted">{{ image.createdAt }}</td>
                                    <td class="language-default text-muted">{{ image.country }}</td>
                                    <td class="status text-muted">{{ image.statusDescription }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div v-if="isVideo" slot="footer" class="align-items-right" style="padding: 0">
                            <b-pagination class="no-border"
                                v-model="currentPage"
                                :total-rows="totalVideosFiltered"
                                :per-page="perPage"
                                align="right"
                                style="padding: 0"
                                @input="updatePage()"
                                hide-goto-end-buttons>
                            </b-pagination>
                        </div>
                        <div  v-if="!isVideo" slot="footer" class="align-items-right" style="padding: 0">
                            <b-pagination class="no-border"
                                v-model="currentPage"
                                :total-rows="totalImagesFiltered"
                                :per-page="perPage"
                                align="right"
                                style="padding: 0"
                                @input="updatePage()"
                                hide-goto-end-buttons>
                            </b-pagination>
                        </div>
                    </card>
                </div>
            </div>
        </div>

        <b-modal size="xl" ref="image-modal" v-bind:title="image.userNickname" :hide-footer=true>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <img width="500" height="400" :src="image.imageUrl">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 v-text="image.imageName"></h4>
                            </div>
                        </div>
                        <div class="row upload-date">
                            <div class="col-md-12">
                                <span v-text="$t('videoList.videoModal.uploaded') + image.uploaded"></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <span>{{ $t('videoList.videoModal.status') }} </span> <span v-text="image.statusDescription" class="status-text"></span>
                            </div>
                        </div>
                        <div class="row reward-aht">
                            <div class="col-md-3">
                                <input v-model="image.aht" class="form-control form-control-sm" :disabled="(image.rewardTx !== '')"/>
                            </div>
                            <div class="col-md-9">
                                <span>{{ $t('videoList.videoModal.reward') }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 approve">
                                <base-button type="default-button" class="modal-button" @click="approveImage(image)" :disabled="(image.status === 1)">
                                    {{ $t('videoList.videoModal.approve') }}   
                                </base-button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{ $t('videoList.videoModal.rejectionNote') }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" rows="4" v-model="image.rejectionNote"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 reject">
                                <base-button size="lg" type="default" class="modal-button" @click="rejectImage(image)" :disabled="(image.status === 2)">
                                    {{ $t('videoList.videoModal.reject') }}
                                </base-button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </b-modal>
        
        <b-modal size="xl" ref="video-modal" v-bind:title="video.userNickname" :hide-footer=true>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-6">
                        <div class="embed-responsive embed-responsive-16by9">
                            <video width="360" height="280" crossorigin="use-credentials" controls>
                                <source v-bind:src="video.videoUrl" type="video/mp4" crossorigin="use-credentials">
                            </video>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-12">
                                <h4 v-text="video.videoName"></h4>
                            </div>
                        </div>
                        <div class="row upload-date">
                            <div class="col-md-12">
                                <span v-text="$t('videoList.videoModal.uploaded') + video.uploaded"></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <span>{{ $t('videoList.videoModal.status') }} </span> <span v-text="video.statusDescription" class="status-text"></span>
                            </div>
                        </div>
                        <div class="row reward-aht">
                            <div class="col-md-3">
                                <input v-model="video.aht" class="form-control form-control-sm" :disabled="(video.rewardTx !== '')"/>
                            </div>
                            <div class="col-md-9">
                                <span>{{ $t('videoList.videoModal.reward') }}</span>
                            </div>
                        </div>
                        <div class="row auto-approve">
                            <div class="col-md-12">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" v-model="video.autoApprove"  >
                                    <label class="form-check-label" for="defaultCheck1">
                                        {{ $t('videoList.videoModal.autoapprove') }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 approve">
                                <base-button type="default-button" class="modal-button" @click="approve(video)" :disabled="(video.status === 1)">
                                    {{ $t('videoList.videoModal.approve') }}   
                                </base-button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-12">
                                <h3>{{ $t('videoList.videoModal.rejectionNote') }}</h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <textarea class="form-control" rows="4" v-model="video.rejectionNote"></textarea>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 reject">
                                <base-button size="lg" type="default" class="modal-button" @click="reject(video)" :disabled="(video.status === 2)">
                                    {{ $t('videoList.videoModal.reject') }}
                                </base-button>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </b-modal>

        <b-modal size="lg" ref="default-video-modal" v-bind:title="$t('videoList.defaultVideoModal.title') + video.lang" :hide-footer=true>
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="embed-responsive embed-responsive-16by9">
                            <video v-if="video.videoUrl" width="360" height="280" crossorigin="use-credentials" controls>
                                <source v-bind:src="video.videoUrl" type="video/mp4" crossorigin="use-credentials">
                            </video>
                        </div>
                    </div>
                </div>
                <div class="row language-select">
                    <div class="col-md-6">
                        <b-form-select v-model="languageSelected"
                            :options="languages">
                        </b-form-select>
                    </div>
                    <div class="col-md-6 element">
                        <label for="file-upload" class="custom-file-upload">
                            <i class="fa fa-upload fa-2x"></i>
                        </label>
                        <b-form-file id="file-upload" v-model="defaultVideo" @change="setVideo" type="file"/>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 upload-date">
                        <span v-show="video.uploaded !== undefined" v-text="$t('videoList.defaultVideoModal.uploaded') + video.uploaded"></span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 approve">
                        <base-button type="default-button" class="modal-button" @click="save(video)">
                            {{ $t('videoList.defaultVideoModal.save') }}   
                        </base-button>
                        <base-button v-show="video.uploaded !== undefined" size="lg" type="default" class="modal-button" @click="removeDefaultVideo(video)">
                            {{ $t('videoList.defaultVideoModal.remove') }}
                        </base-button>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import moment from 'moment'
import langs from '../store/modules/langs.js'
import LoginService from '@/services/LoginService.js'

    export default {
        methods: {
            search: function() {
                let filters= []

                if (this.selectedStatus && this.selectedStatus.length) {
                    for (let i = 0, total = this.selectedStatus.length; i < total; i++) {
                        filters.push('status[]=' + Number(this.selectedStatus[i].code))
                    }
                }

                if (this.dateRange.length) {
                    filters.push('from=' + moment(this.dateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                    filters.push('to=' + moment(this.dateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                }

                if (this.name.trim() !== '') {
                    filters.push('name=' + this.name.trim())
                }

                filters.push('page=' + this.currentPage)
                
                this.$store.dispatch('getVideoList', filters)
                this.$store.dispatch('getCountVideos', filters)
            },
            searchDefaultVideo: function() {
                let filters= []

                if (this.dateRange.length) {
                    filters.push('from=' + moment(this.dateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                    filters.push('to=' + moment(this.dateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                }

                filters.push('page=' + this.currentPage)

                this.$store.dispatch('getDefaultVideoList', filters)
                this.$store.dispatch('getCountDefaultVideos', filters)
            },
            searchImages: function () {
                let filters= []

                if (this.selectedStatus && this.selectedStatus.length) {
                    for (let i = 0, total = this.selectedStatus.length; i < total; i++) {
                        filters.push('status[]=' + Number(this.selectedStatus[i].code))
                    }
                }

                if (this.dateRange.length) {
                    filters.push('from=' + moment(this.dateRange[0], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                    filters.push('to=' + moment(this.dateRange[1], 'DD/MM/YYYY').format('YYYY-MM-DD'))
                }

                if (this.name.trim() !== '') {
                    filters.push('name=' + this.name.trim())
                }

                filters.push('page=' + this.currentPage)
                
                this.$store.dispatch('getImageList', filters)
                this.$store.dispatch('getCountImages', filters)
            },
            showImageList: function() {
                this.imageListButtonClass = 'default-button'
                this.videoListButtonClass = 'secondary'
                this.defaultVideoListButtonClass = 'secondary'
                this.isVideo = false

                this.currentPage = 1

                this.searchImages()
            },
            showVideoList: function() {
                this.videoListButtonClass = 'default-button'
                this.defaultVideoListButtonClass = 'secondary'
                this.imageListButtonClass = 'secondary'
                this.isVideo = true

                this.currentPage = 1

                this.search()
            },
            showDefaultVideoList: function() {
                this.videoListButtonClass = 'secondary'
                this.defaultVideoListButtonClass = 'default-button'
                this.imageListButtonClass = 'secondary'
                this.isVideo = true

                this.currentPage = 1

                this.searchDefaultVideo()
            },
            showImage: function(image) {
                this.$refs['image-modal'].show()
                this.image = image
            },
            showVideo: function(video) {
                if (this.videoListButtonClass !== 'secondary'){
                    this.$refs['video-modal'].show()
                } else {
                    this.$refs['default-video-modal'].show()
                    this.languageSelected = video.lang.toLowerCase()
                }

                this.video = video
            },
            showVideoById: async function (id) {
                let status = ['Waiting for review', 'Active', 'Rejected']
                let response =  await LoginService.getVideoById(id)
                
                let video = {
                    'videoId': response.data[0]._id,
                    'videoName': response.data[0].key,
                    'createdAt': moment(response.data[0].uploadDate).format('MMMM DD, YYYY hh:mm A'),
                    'uploaded': moment(response.data[0].uploadDate).format('DD-MM-YYYY'),
                    'language': langs[response.data[0].lang].name,
                    'status': response.data[0].status,
                    'statusDescription': status[response.data[0].status],
                    'userAddress': response.data[0].user.address,
                    'userNickname': response.data[0].user.userNickname,
                    'videoUrl': response.data[0].videoUrl,
                    'aht': 0,
                    'autoApprove': response.data[0].user.autoApprove,
                    'rejectionNote': response.data[0].rejectionNote
                }
                
                this.showVideo(video)
            },
            approve: async function(video) {
                if (this.currentAccount) {
                    video.address = this.currentAccount

                await this.$store.dispatch('approveVideo', video)
                    .then(() => {
                        this.$refs['video-modal'].hide()
                        this.search()
                        this.$alert('Video approved');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
                } else {
                    this.$alert(
                        'Please, connect with Metamask',
                        'Warning',
                        'warning')
                }
            },
            reject: async function(video) {
                await this.$store.dispatch('rejectVideo', video)
                    .then(() => {
                        this.$refs['video-modal'].hide()
                        this.search()
                        this.$alert('Video rejected');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
            },
            async approveImage (image) {
                if (this.currentAccount) {
                    image.address = this.currentAccount

                    await this.$store.dispatch('approveImage', image)
                        .then(() => {
                            this.$refs['image-modal'].hide()
                            this.searchImages()
                            this.$alert('Image approved');
                        }).catch ((error) => {
                            this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
                } else {
                    this.$alert(
                        'Please, connect with Metamask',
                        'Warning',
                        'warning')
                }
            },
            async rejectImage (image) {
                await this.$store.dispatch('rejectImage', image)
                    .then(() => {
                        this.$refs['image-modal'].hide()
                        this.searchImages()
                        this.$alert('Image rejected');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)
                    })
            },
            updatePage: function() {
                if (this.videoListButtonClass === 'default-button'){
                    this.search()
                } else if (this.defaultVideoListButtonClass === 'default-button') {
                    this.searchDefaultVideo()
                } else if (this.imageListButtonClass === 'default-button') {
                    this.searchImages()
                }
            },
            addDefaultVideo: function() {
                this.$refs['default-video-modal'].show()
                this.video = {
                    'videoUrl': null
                }
                this.languageSelected = ''
            },
            removeDefaultVideo: async function(defaultVideo) {
                this.$store.dispatch('removeDefaultVideo', defaultVideo)
                    .then(() => {
                        this.$refs['default-video-modal'].hide()
                        this.searchDefaultVideo()
                        this.$alert('Video removed');
                    }).catch ((error) => {
                        this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                        return Promise.reject(error.response)         
                    })
            },
            save: function() {
                if (this.defaultVideo !== null && this.languageSelected !== '') {

                    let video = {
                        'name': this.defaultVideo.name,
                        'videoFormat': 'video/mp4',
                        'lang': this.languageSelected,
                        'file': this.defaultVideo,
                        'videoCover': this.defaultVideoImage
                    }

                    this.$store.dispatch('uploadDefaultVideo', video)
                        .then(() => {
                            this.$refs['default-video-modal'].hide()
                            this.searchDefaultVideo()
                            this.$alert('Successful upload');
                        }).catch ((error) => {
                            this.$alert(
                                'Something went wrong, Please try again',
                                'Warning',
                                'warning')
                            return Promise.reject(error.response)
                        })
                }
            },
            setVideo: function(e) {
                const file = e.target.files[0];
                this.video.videoUrl = URL.createObjectURL(file);
                this.getImageBase64(file)
            },
            getImageBase64: function(file) {
                let fileReader = new FileReader();
                const self = this;
                fileReader.onload = function() {
                    let blob = new Blob([fileReader.result], {type: file.type});
                    let url = URL.createObjectURL(blob);
                    let video = document.createElement('video');
                    
                    let timeupdate = function() {
                        if (snapImage()) {
                            video.removeEventListener('timeupdate', timeupdate);
                            video.pause();
                        }
                    };
                    
                    video.addEventListener('loadeddata', function() {
                        self.defaultVideoImage = snapImage()
                        video.removeEventListener('timeupdate', timeupdate);
                    });
                    
                    let snapImage = function() {
                        let canvas = document.createElement('canvas');
                        canvas.width = video.videoWidth;
                        canvas.height = video.videoHeight;
                        canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                        let image = canvas.toDataURL('image/jpeg', 0.6);
                        return image;
                    };
                    
                    video.addEventListener('timeupdate', timeupdate);
                    video.preload = 'metadata';
                    video.src = url;
                    video.muted = true;
                    video.playsInline = true;
                    video.play();
                };
                fileReader.readAsArrayBuffer(file)
            }
        },
        async mounted() {
            await this.search()
            if (this.$router.history.current.params.id) {
                this.showVideoById(this.$router.history.current.params.id)
            }
        },
        computed: {
            images () {
                return this.$store.state.stats.images;
            },
            totalImages () {
                return this.$store.state.stats.totalImages
            },
            totalImagesFiltered () {
                return this.$store.state.stats.totalImagesFiltered
            },
            videos() {
                return this.$store.state.stats.videos
            },
            totalVideos() {
                return this.$store.state.stats.totalVideos
            },
            totalVideosFiltered() {
                return this.$store.state.stats.totalVideosFiltered
            },
            isDisable() {
                return this.video.status == 1
            },
            languages() {
                let languages = []
                languages.push({
                    'value': '',
                    'text': 'Language'
                })
                for(let language in langs) {
                    languages.push({
                        'value': language,
                        'text': langs[language].name
                    })
                }

                return languages;
            },
            currentAccount () {
                return this.$store.getters.getAddressMetamask
            },
        },
        components: {
            Multiselect
        },
        data: function() {
            return {
                name: '',
                selectedStatus: null,
                status: [
                    {"code": "0", "label": "Waiting for review"},
                    {"code": "1", "label": "Active"},
                    {"code": "2", "label": "Rejected"},
                ],
                dateRange: [],
                dateOptions: {
                    startDate: '2019-07-25',
                    maxDate: moment().toDate(),
                    locale: {
                        format: 'YYYY-MM-DD'
                    },
                    ranges: {
                        'Today': [moment(), moment()],
                        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                        'This Month': [moment().startOf('month'), moment()],
                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                    }
                },
                currentPage: 1,
                perPage: 20,
                videoListButtonClass: 'default-button',
                defaultVideoListButtonClass: 'secondary',
                imageListButtonClass: 'secondary',
                image: {},
                video: {},
                languageSelected: '',
                defaultVideo: null,
                defaultVideoImage: '',
                isVideo: true,
            }
        },
        watch: {
            '$route' (value) {
                if (value.params.id) {
                    this.showVideoById(value.params.id)
                }
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
    .custom__tag {
    display: inline-block;
    background-color: #285150;
    padding: 5px 3px;
    color: white;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 0.375rem;
  }

  .custom__remove {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
    color: rgb(244,121,110);
    font-weight: bold;
  }

  .video-table{
      width: 100%;
      margin-left: 1px;
      margin-right: 1px;
      float: none;
  }

  thead tr {
    background-color: #7a92aa;
  }

  tbody tr:hover {
      background: rgba(122, 146, 170, .40)
  }

  /* tbody tr:nth-child(even){
    background-color: rgb(211, 227, 240)
  } */

  td {
    text-align: left;
    padding-left: 2%;
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
  }

  th {
    color: white;
    text-align: left;
    padding-left: 2%;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  td.name-video-default {
      width: 37%;
  }

  td.user-name {
      width: 13%;
  }

  td.created-date-default {
      width: 23%;
  }

  td.language-default {
      width: 10%;
  }

  td.status {
      width: 15%;
  }

  td.name-video {
      width: 50%;
  }

  td.created-date {
      width: 30%;
  }

  td.language {
      width: 20%;
  }

  .no-border {
      border-style: none !important;
  }

  .status-text {
      color:#f85d26;
  }

  .reject {
      padding-top: 12px;
      padding-bottom: 4px;
  }

  .modal-button {
      padding-top: 1%;
      padding-bottom: 1%;
  }

  .approve {
      padding-top: 12px;   
  }

  .auto-approve {
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .reward-aht {
      padding-top: 30px;
  }

  .upload-date {
      padding-bottom: 8px;
  }

  .language-select {
      padding-top: 10px;
      padding-bottom: 10px;
  }

  .upload-default-video {
      padding-top: 10px;
      width: 100%;
      text-align: center;
  }

  .upload-default-video i.fa-upload {
    font-size: inherit;
  }
  
  .element {
      display: inline-flex;
      align-items: center;
  }

  i.fa-upload {
      margin: 10px;
      cursor: pointer;
      font-size: 30px;
  }
  
  i:hover {
      opacity: 0.6;
  }
  
  .custom-file {
      display: none !important;
  }
</style>