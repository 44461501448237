<template>
    <div>
        <base-header
            type="secondary"
            class="pb-6 pb-8 pt-2 pt-md-7">
            <h2>{{ $t('transactionScan.filter') }}</h2>

            <div class="row">
                <div class="col-12 col-md-10 pt-2">
                    <base-input :placeholder="$t('transactionScan.placeholderSearch')" v-model="data">
                    </base-input>
                </div>
                <div class="col-12 col-md-2 pt-2">
                    <base-button type="default-button" @click="search">
                        {{ $t('transactionScan.search') }}
                    </base-button>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <card v-show="transaction || address" header-classes="bg_transparent" body-classes="d-flex" class="mb-3">
                        <div slot="header" class="row align-items-center">
                            <h5 v-if="transaction"  class="h3 mb-0">{{  $t('transactionScan.transactionInfo') }}</h5>
                            <h5 v-if="address" class="h3 mb-0">{{  $t('transactionScan.addressInfo', { address: addressSelected }) }}</h5>
                        </div>
                        <div class="container" v-if="transaction">
                            <div class="row">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.transactionHash') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.hash }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.status') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ getStatus(transactionDetails.success) }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.block') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.blockNumber }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.from') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.from }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.to') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.to }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.nonce') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.nonce }}
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-3">
                                    {{  $t('transactionScan.transaction.input') }}
                                </div>
                                <div class="col-9 text-left">
                                    {{ transactionDetails.input }}
                                </div>
                            </div>
                        </div>
                        <div class="container" v-if="address">
                            <div class="row">
                                <div class="col-5">
                                    {{  $t('transactionScan.address.totalTransactions') }}
                                </div>
                                <div>
                                    {{ transactionCount }}
                                </div>
                            </div>
                            <div class="row pt-3">
                                <div class="col-5">
                                    {{  $t('transactionScan.address.enrolled') }}
                                </div>
                                <div>
                                    {{ isEnrolled }}
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const web3v1Factory = require('../lib/web3v1.3.0Helper') 

export default {
    data () {
        return {
            data: '',
            transaction: false,
            address: false,
            addressSelected: ''
        }
    },
    computed: {
        transactionCount () {
            return this.$store.getters.getTransactionCount
        },
        transactionDetails () {
            return this.$store.getters.getTransactionDetails
        },
        isEnrolled () {
            return this.$store.getters.getIsEnrolled ? 'Yes' : 'No'
        }
    },
    methods: {
        async search () {
            if (this.isTransactionHash(this.data)) {
                await this.$store.dispatch('getTransactionReceipt', this.data)
                this.address = false
                this.transaction = true
            } else if (this.isETHAddress(this.data)) {
                await this.$store.dispatch('getTransactionCount', this.data)
                await this.$store.dispatch('getIsEnrolled', this.data)
                this.addressSelected = this.data
                this.transaction = false
                this.address = true
            } else {
                this.$alert(
                    this.$t('transactionScan.error'),
                    'Error',
                    'error')
            }
        },
        isTransactionHash (txnHash) {
            const regex = /^0x([A-Fa-f0-9]{64})$/

            return txnHash.match(regex)
        },
        isETHAddress (address) {
            const web3 = web3v1Factory.getInstance()

            return web3.utils.isAddress(address)
        },
        getStatus (status) {
            let statusText = ''

            switch (status) {
                case -1:
                    statusText = this.$t('transactionScan.transactionStatus.pending')
                    break;
                case 0:
                    statusText = this.$t('transactionScan.transactionStatus.failed')
                    break;
                case 1:
                    statusText = this.$t('transactionScan.transactionStatus.success')
                    break;
            }

            return statusText
        }
    }    
}
</script>